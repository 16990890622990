// noinspection CssUnknownTarget
@import '~styles/mixins';

.layout {
  display: flex;
  flex-direction: column;
  min-width: 375px;
  min-height: 100vh;
}

.main {
  flex-grow: 1;
  padding-top: 96px;

  @include b-d(md) {
    padding-top: 80px;
  }
}

%blendShadow {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 10;
  display: block;
  width: $container-padding-x-xs;
  content: '';

  @include b-up(md) {
    display: none;
  }
}

.navMenuScrollOuter {
  position: relative;
  margin-top: 16px;

  @include b-up(lg) {
    display: none;
  }

  &::before {
    @extend %blendShadow;

    left: 0;
    background: linear-gradient(
      90deg,
      rgba($body-bg, 1) 0%,
      rgba($body-bg, 0) 100%
    );
  }

  &::after {
    @extend %blendShadow;

    right: 0;
    background: linear-gradient(
      270deg,
      rgba($body-bg, 1) 0%,
      rgba($body-bg, 0) 100%
    );
  }
}

.navMenuScrollInner {
  @include hideScrollbar;

  width: 100%;
  overflow-y: scroll;

  @include b-up(md) {
    display: flex;
    justify-content: center;
  }
}

.navMenuContainer {
  display: inline-flex;
  width: auto;
}
