@import '~styles/mixins';

.blockMain {
  display: grid;
  row-gap: 24px;
  width: 100%;

  > div {
    display: grid;
    row-gap: 16px;
  }

  @include b-up(lg) {
    max-width: 470px;
    margin-left: 132px;
  }

  @include b-d(lg) {
    > div {
      row-gap: 24px;
    }
  }

  .stakeCalculator {
    @include b-d(lg) {
      margin-top: -8px;
    }
  }
}
