// noinspection CssUnknownTarget
@import '~styles/mixins';

.blockTokenPrice {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 24px 1fr;
  column-gap: 8px;
  padding: 12px 14px;
  border: 1px solid $gray-800;
  border-radius: 12px;
}

.logo {
  grid-row: 1 / 3;
}

.textLabel {
  @include font-family-joyride;

  font-size: 8px;
  color: $gray-500;
  letter-spacing: 0.01em;
}

.blockPrice {
  @include text-joyride-13;
}

.textPrice {
  color: transparent;
  background: radial-gradient(
    98.54% 76.07% at 93.79% 17.7%,
    #99bded 40%,
    #9cecca 50%,
    #ffddb4 140%
  );

  // noinspection CssInvalidPropertyValue
  background-clip: text;
}
