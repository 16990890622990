// noinspection CssUnknownTarget
@import '~styles/mixins';

.cardInfo {
  @include fix-ios-border-radius;

  display: grid;
  grid-auto-flow: column;
  align-items: center;
  overflow: hidden;
  border-radius: 24px;

  @include b-d(ipad) {
    grid-auto-flow: row;
  }

  &::after {
    position: absolute;
    top: 16px;
    right: 16px;
    bottom: 16px;
    left: 24px;
    z-index: -1;
    content: '';
    background: linear-gradient(
        transparent 5%,
        rgb(154 59 204 / 30%) 5%,
        rgb(154 59 204 / 30%) 100%
      ),
      linear-gradient(180deg, #4b98cd 0%, #1fcfb1 100%);
    filter: blur(16px);
    border-radius: 91px;
    transform: translateZ(0%);

    @include b-d(lg) {
      top: 20px;
      right: 12px;
      bottom: 20px;
      left: 12px;
    }

    @include b-d(md) {
      top: 8px;
      right: 11px;
      bottom: 8px;
      left: 11px;
    }
  }
}

.baseLayer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  min-height: 100%;
  padding: 22px 24px;
  background-color: rgb(24 24 24 / 72%);
  border-radius: 24px;
  transform: translateZ(0);

  @include b-d(lg) {
    padding: 24px;
  }

  @include b-d(md) {
    padding: 17px;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 2px;
    content: '';
    background: linear-gradient(170deg, #333 0%, #181818 100%);
    border-radius: 26px;
    mask: linear-gradient(#fff 0, #fff 100%) content-box,
      linear-gradient(#fff 0, #fff 100%) border-box;
    mask-composite: exclude;
  }

  &.button {
    @include b-up(lg) {
      grid-template-columns: auto 1fr auto;
    }
  }
}

.btnWrapper {
  @include b-btw(md, lg) {
    grid-column: 2 span;
  }

  @include b-d(md) {
    margin-top: 10px;
  }
}

.balance {
  &::before,
  &::after {
    top: 22px;
    bottom: 22px;
    left: 5px;
    width: calc(50% - 5px);
    background: linear-gradient(
      0deg,
      #2dbeba -42.37%,
      rgba(66, 164, 199, 0%) 158.95%
    );
    filter: blur(16px);

    @include b-d(lg) {
      top: 14px;
      bottom: 2px;
      left: 24px;
      width: calc(50% - 37px);
    }

    @include b-d(md) {
      top: 16px;
      left: 16px;
      width: calc(100% - 30px);
      height: 52px;
      filter: blur(28px);
    }
  }

  &::before {
    position: absolute;
    z-index: -1;
    content: '';
    border-radius: 91px;
  }

  &::after {
    right: 5px;
    left: auto;
    background: linear-gradient(0deg, #c08001 0%, #461d01 100%);

    @include b-d(lg) {
      right: 24px;
    }

    @include b-d(md) {
      top: 94px;
      left: 16px;
    }
  }

  .baseLayer {
    column-gap: 48px;
    justify-items: center;

    @include b-d(md) {
      grid-template-columns: auto;
      row-gap: 26px;
      justify-content: left;
      padding: 16px;
    }
  }
}

.dark {
  &::after {
    top: 14px;
    right: 24px;
    bottom: 14px;
    left: 15px;
    background: linear-gradient(90deg, #212121 0%, rgba(33, 33, 33, 0%) 93.3%);

    @include b-d(lg) {
      right: 14px;
      bottom: 24px;
      left: 14px;
      background: linear-gradient(
        0deg,
        #212121 26.81%,
        rgba(33, 33, 33, 0%) 149.64%
      );
    }

    @include b-d(md) {
      bottom: 16px;
    }
  }

  &.button::after {
    background: linear-gradient(
      90deg,
      #212121 2.84%,
      rgba(33, 33, 33, 0%) 90.51%
    );

    @include b-d(lg) {
      background: linear-gradient(0deg, #212121 0%, rgba(33, 33, 33, 0%) 94.2%);
    }
  }

  .baseLayer {
    &:not(.button) {
      @include b-up(lg) {
        grid-template-columns: repeat(3, auto);
        justify-content: space-between;
        padding: 22px 62px;
      }

      @include b-btw(md, lg) {
        justify-content: space-around;
      }
    }

    &.button {
      align-items: center;

      @include b-up(lg) {
        column-gap: 43px;
      }

      @include b-d(md) {
        row-gap: 26px;
      }
    }

    @include b-btw(md, lg) {
      grid-template-areas:
        'item1 item2'
        'item3 item3';
      grid-gap: 24px;
      justify-items: center;
      padding: 24px;
    }

    @include b-d(md) {
      grid-template-columns: auto;
      row-gap: 16px;
      padding: 16px;
    }
  }
}
