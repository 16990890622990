// noinspection CssUnknownTarget
@import '~styles/mixins';

.headerNavMenu {
  .navLink {
    @include b-d(lg) {
      padding: 12px 16px !important;
    }
  }
}
