@import '~styles/mixins';

.pageWrapper {
  padding-top: 40px;

  @include b-d(lg) {
    padding-top: 24px;
  }
}

.assetHub {
  display: grid;
  grid-template-columns: 260px 1fr;
  column-gap: 32px;
  align-items: start;
  width: 100%;
  color: white;

  @include b-d(lg) {
    grid-template-columns: auto;
    row-gap: 24px;
  }
}
