@import '~styles/mixins';

.innerContent {
  display: grid;
  row-gap: 8px;

  @include b-btw(md, lg) {
    grid-template-columns: auto 240px;
    grid-auto-flow: column;
    column-gap: 8px;
  }
}
